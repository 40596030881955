<template>
  <div>
    <div class="sealBoxBox">
      <img src="@/assets/image/productCenter/timestampBanner.png" alt="智能印章一体机">
      <h2>智能印章一体机</h2>
      <p>智能印章一体机是由山东豸信认证服务有限公司自主研发，其核心管理目标是实现企业电子印章的智能化管理和安全性管理，使印章使用的全业务流程可控、可追溯，保证相关数据的安全性和合规性，实现印章管理的可信、可靠、安全。</p>
    </div>
    <v-functional :list="functional" />
    <v-featuresItem :list="features" />
    <v-learnMore />
    <v-networkSecurity />
  </div>
</template>

<script>
import learnMore from './components/learnMore.vue';
import featuresItem from './components/featuresItem.vue';
import functionalItem from './components/functionalItem.vue';
import networkSecurity from '../../../components/networkSecurity.vue';
export default {
  data () {
    return {
      functional: [
        {
          icon: 'orgCert-icon1.png',
          title: '证书管理',
          intro:
            '通过数字证书确认网络中传递信息的个人身份，确保个人线上身份同线下身份一致。'
        },
        {
          icon: 'sealBox1.png',
          title: '签章服务',
          intro: '标准电子签章服务、批量电子签章服务、骑缝电子签章服务。 '
        },
        {
          icon: 'sealBox2.png',
          title: '模版管理',
          intro: '模板上传、模板填充；利用模板进行签署。'
        },
        {
          icon: 'mobileShield1.png',
          title: '印章管理',
          intro: '印模和印章的制作、停用、启用、注销等。 '
        },
        {
          icon: 'sealBox3.png',
          title: '审计功能',
          intro: '印章、印模、证书相关日志审计。'
        },
        {
          icon: 'sealBox4.png',
          title: '云端监控',
          intro: '监控硬件的状态，包括密钥对、内存、在线管理等。'
        },
        {
          icon: 'orgCert-icon3.png',
          title: 'HA',
          intro: '高可用、支持双机部署。'
        },
        {
          icon: 'sealBox5.png',
          title: '接口服务',
          intro:
            '包含证书管理、签署管理、印章管理、监控管理、日志管理等功能的接口对外提供。'
        }
      ],
      features: [
        {
          title: '安全',
          intro:
            '企业级印章/证书统一安全存储；软硬件一体化，防侵入；专用网络接口；证书发放需用户确认，安全可靠；使用记录存储于设备内部，可追溯。'
        },
        {
          title: '合规',
          intro:
            '内置国密型号密码卡；私钥驻留加密卡内部，永不出设备；签名算法支持国产密码标准；数字证书由豸信CA直接颁发。'
        },
        {
          title: '扩展',
          intro:
            '标准化API接口搭配SDK，无缝对接各类业务系统；松散耦合、良好的开放性、可标准、动态集成。'
        },
        {
          title: '易交付',
          intro:
            '一体化交付，标准化产品体验提升；标准化运维体系，降低运维成本。'
        }
      ],
    }
  },
  components: {
    'v-learnMore': learnMore,
    'v-featuresItem': featuresItem,
    'v-functional': functionalItem,
    'v-networkSecurity': networkSecurity,
  },
}
</script>

<style lang="scss" scoped>
.sealBoxBox {
  width: 7.5rem;
  height: 9.8rem;
  background: #f0f4ff;
  padding: 0.65rem 0.53rem 1.12rem;
  box-sizing: border-box;
  text-align: center;
  img {
    width: 5.29rem;
  }
  h2 {
    font-size: 0.48rem;
    color: #303b50;
    line-height: 0.67rem;
    margin: 0.64rem 0 0.26rem;
  }
  p {
    font-size: 0.28rem;
    color: #303b50;
    line-height: 0.44rem;
  }
}
</style>